import React, { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import cx from "classnames";
import axios from "axios";
import { InputMask } from "primereact/inputmask";
import { motion } from "framer-motion";
import {
  IoCheckmarkSharp,
  IoHomeOutline,
  IoCallOutline,
  IoMailOpenOutline,
  IoArrowBackOutline,
} from "react-icons/io5";
import { StaticImage } from "gatsby-plugin-image";

import * as global from "../styles/global.module.scss";
import * as styles from "./form.module.scss";

function Form({ close, callback, slug }) {
  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState({
    form: callback.name,
    name: "",
    email: "",
    phone: "",
    agreement: false,
    complete: false,
    status: "success",
  });

  const isNumber = contact.phone !== "" && !contact.phone.includes("_");

  const formName = `ym_${callback.id}`;
  const formNameWithSlug = `ym_${callback.id}_${slug}`;

  const activeSubmite =
    contact.name !== "" &&
    contact.email !== "" &&
    isNumber &&
    contact.agreement;

  const apiUrl = "/api/sendFormMail.php";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const roistatId = window.roistat ? window.roistat.getVisit() : null;

    // Ваш код для отправки данных формы на сервер
    axios
      .post(apiUrl, {
        form_name: contact.form,
        user_name: contact.name,
        user_email: contact.email,
        user_phone: contact.phone,
        roistat: roistatId,
      })
      .then(function () {
        // Отправляем отчет о форме в яндекс метрику
        window.ym(96023603, "reachGoal", "ym_all_form");
        window.ym(96023603, "reachGoal", formName);
        window.ym(96023603, "reachGoal", formNameWithSlug);

        setContact((prev) => ({ ...prev, complete: true }));
        if (callback.presentation) {
          window.open(callback.presentation);
        }
      })
      .catch((err) => console.log(err));

    // Очистка полей формы после отправки
  };

  return (
    <div className={styles.container}>
      <motion.div
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.4,
            ease: [0.24, 0.68, 0.79, 0.96],
          },
        }}
        initial={{ opacity: 0, y: 20 }}
        className={styles.block}
      >
        <div className={styles.content}>
          {contact.complete ? (
            <div className={styles.answer}>
              {contact.status === "success" && (
                <div className={styles.complete}>
                  <h3>{contact.name}, спасибо за заявку!</h3>
                  {callback.presentation ? (
                    <>
                      <p>
                        Вы можете скачать презентацию по ссылке ниже или
                        ознакомиться с ней в открытой вкладке.
                      </p>
                      <div className={styles.action}>
                        <a
                          href={callback.presentation}
                          download={true}
                          className={cx(global.button, global.green)}
                        >
                          <p>Скачать презентацию</p>
                        </a>
                        <button
                          type="button"
                          className={cx(global.button, global.border)}
                          onClick={close}
                        >
                          <p>Вернуться к сайту</p>
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className={styles.action}>
                      <p>Наш специалист свяжется с Вами в ближайшее время.</p>
                      <button
                        type="button"
                        className={cx(styles.button, styles.back)}
                        onClick={close}
                      >
                        <IoArrowBackOutline className={styles.icon} />
                        <p>Вернуться к сайту</p>
                      </button>
                    </div>
                  )}
                </div>
              )}
              {contact.status === "error" && (
                <div className={styles.error}>
                  <h3>Что-то пошло не так...</h3>
                  <p>
                    К сожалению, мы не смогли отправить Вашу форму. Но Вы можете
                    связаться с нами тут:
                  </p>
                  <div className={styles.resultfooter}>
                    <a href="call:+74950854858" className={styles.button}>
                      <IoCallOutline className={styles.icon} />
                      <p>+7 (495) 085-48-58</p>
                    </a>
                    <a
                      href="mailto:info@moe-prostranstvo.ru"
                      className={styles.button}
                    >
                      <IoMailOpenOutline className={styles.icon} />
                      <p>info@moe-prostranstvo.ru</p>
                    </a>
                    <button
                      type="button"
                      className={cx(styles.button, styles.back)}
                      onClick={close}
                    >
                      <IoHomeOutline className={styles.icon} />
                      <p>Вернуться к сайту</p>
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.contact}>
              <div className={styles.head}>
                <h3>{callback.title}</h3>
                <p>{callback.description}</p>
              </div>

              <form
                className={styles.form}
                onSubmit={handleSubmit}
                id={`body_${callback.id}`}
              >
                <input
                  type="hidden"
                  name="roistat"
                  value={window.roistat ? window.roistat.getVisit() : null}
                />
                <div className={styles.input}>
                  <input
                    required={true}
                    autoComplete="off"
                    value={contact.name}
                    name="user_name"
                    onChange={(e) =>
                      setContact((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                  <span className={styles.label}>Имя</span>
                </div>
                <div className={styles.input}>
                  <input
                    required={true}
                    autoComplete="off"
                    value={contact.email}
                    name="user_email"
                    onChange={(e) =>
                      setContact((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                  <span className={styles.label}>Электронная почта</span>
                </div>
                <div className={styles.input}>
                  <InputMask
                    required={true}
                    autoComplete="off"
                    value={contact.phone}
                    name="user_phone"
                    onChange={(e) =>
                      setContact((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                    mask="+7 (999) 999-99-99"
                  />
                  <span className={styles.label}>Телефон</span>
                </div>

                <button
                  className={cx(
                    styles.checkbox,
                    contact.agreement && styles.checked
                  )}
                  type="button"
                  onClick={() =>
                    setContact((prev) => ({
                      ...prev,
                      agreement: !prev.agreement,
                    }))
                  }
                >
                  <div className={styles.box}>
                    <IoCheckmarkSharp className={styles.icon} />
                  </div>
                  <p className={styles.label}>
                    Я согласен(-на) на обработку моих персональных данных
                  </p>
                </button>
                <div className={styles.submit}>
                  <button
                    className={cx(
                      global.button,
                      global.green,
                      !activeSubmite && global.nonactive,
                      isLoading && global.loading
                    )}
                    type="submit"
                    id={callback.id}
                  >
                    {isLoading ? <p>Подождите...</p> : <p>Отправить</p>}
                  </button>
                </div>
              </form>
            </div>
          )}
          <button className={styles.close} onClick={close}>
            <IoCloseOutline className={styles.icon} />
          </button>
          <div className={styles.photo}>
            <StaticImage
              src="../images/form.jpg"
              alt="Готовый загородный дом"
              className={styles.image}
            />
          </div>
        </div>
      </motion.div>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        className={styles.background}
        onClick={close}
      />
    </div>
  );
}

export default Form;
